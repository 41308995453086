::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    overflow-x: hidden;
}
::-webkit-scrollbar-track{
    background-color: #e5e5e5;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb{
  
  background-color:#C2C1C1 !important;
  border-radius: 10px;
}